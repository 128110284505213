export const appIntroduce = {
  ko: '국내 의류 B2B 시장 최초 도입된 블록체인 기반 고객 리워드 모바일 앱 서비스',
  en: 'Blockchain-based customer reward mobile app service introduced for the first time in the wholesale B2B clothing market in Korea',
};

export const clearAdoption = {
  ko: {
    title: '의류 도매시장 맞춤형 서비스',
    text: [
      { id: 0, value: '의류 도매 브랜드 1,000+' },
      { id: 1, value: '누적 리워드 서비스 이용 횟수 300,000+' },
    ],
  },
  en: {
    title: 'Customized services for the wholesale clothing market',
    text: [
      { id: 0, value: 'B2B Wholesale brands 1,000+' },
      { id: 1, value: 'Accumulated reward service usage 300,000+' },
    ],
  },
};

export const qr = {
  ko: {
    title: 'QR 코드 기반 결제 & 리워드 서비스',
    text: [
      { id: 0, value: '간편 QR 코드 스캔을 통한 페이먼트 솔루션' },
    ],
  },
  en: {
    title: 'QR Code-based payment solution & reward service',
    text: [
      { id: 0, value: 'Support for simple QR code scanning such as using apM NFT Vouchers and free shuttles' },
    ],
  },
};

export const voucher = {
  ko: {
    title: 'apM NFT 전자상품권',
    description: '한국 의류 도매시장 최초 도입된 온체인 전자상품권 서비스',
    text: [
      // { id: 0, value: 'apM 코인으로 결제 가능' },
      { id: 1, value: '간편 QR 코드 스캔 결제 지원' },
      { id: 2, value: '랩핑&언랩핑 등 2차 거래 지원' },
    ],
  },
  en: {
    title: 'apM NFT e-Voucher',
    description: 'On-Chain e-Voucher; introduced for the first time in the Korean B2B fashion industry',
    text: [
      // { id: 0, value: 'Available for purchase with apM Coin' },
      { id: 1, value: 'Simple QR Code scan payment through apM Members' },
      { id: 2, value: 'Warp & Unwarp and Secondary trading available' },
    ],
  },
};

export const customizedService = {
  ko: {
    title: 'apM 멤버스 어드민',
    description: '블록체인 기반 백오피스(Back Office)',
    text: [
      { id: 0, value: 'apM 코인의 디지털 자산관리 지원' },
      { id: 1, value: '복수의 디지털 자산 관리 지원' },
      // { id: 1, value: '매장(입점 도매브랜드), 유저 관리 가능' },
      // { id: 2, value: 'apM 쇼핑몰 내 카페, 부대시설 이용과 같은 리워드 관리 가능' },
    ],
  },
  en: {
    title: 'apM Members Admin',
    description: 'Blockchain-based Back Office',
    text: [
      { id: 0, value: 'Manage on and off-chain apM Coin’s digital assets at once' },
      { id: 1, value: 'Support for managing multiple digital assets' },
      // { id: 1, value: 'Store(wholesale brands), user management' },
      // { id: 2, value: 'Rewards management such as the use of cafeterias and auxiliary facilities in apM Malls' },
    ],
  },
};
