import React from 'react';
import styled from 'styled-components';

const Container = styled.footer`
`;

const FooterBackground = styled.div`
  background-color: #000;
`;

const FooterWrapper = styled.div`
  width: 1050px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-family: GmarketSans;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.6;
  letter-spacing: -0.31px;
  color: #969696;

  @media all and (min-width:568px) and (max-width:1023px) {
    width: 600px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    width: 345px;
  }

  @media all and (max-width:320px) {
    width: 270px;
  }
`;

function Footer() {
  return (
    <Container>
      <FooterBackground>
        <FooterWrapper>
          ©2022. apM Members. All Rights Reserved.
        </FooterWrapper>
      </FooterBackground>
    </Container>
  );
}

export default Footer;
