import React from 'react';
import styled from 'styled-components';

import { header } from '../meta/images';

const Container = styled.header`
`;

const Wrapper = styled.div`
  width: 1050px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  
  @media all and (min-width:568px) and (max-width:1023px) {
    width: 600px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    width: 345px;
  }

  @media all and (max-width:320px) {
    width: 260px;
  }
`;

const UpperBackground = styled.div`
  display: flex;
  align-items: center;
  height: 96px;
  background-color: #000;

  @media all and (min-width:568px) and (max-width:1023px) {
  }

  @media all and (min-width:321px) and (max-width:567px) {
    height: 56px;
  }

  @media all and (max-width:320px) {
    height: 56px;
  }
`;

const UpperWrapper = styled( Wrapper )`
`;

const Logo = styled.img`
  width: 250px;
  height: 40px;

  @media all and (min-width:568px) and (max-width:1023px) {
  }

  @media all and (min-width:321px) and (max-width:567px) {
    width: 152px;
    height: 24px;
  }

  @media all and (max-width:320px) {
    width: 132px;
    height: 20px;
  }
`;

const Languages = styled.div`
  display: flex;
`;

const Lauguage = styled.div`
  margin-right: 20px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.38px;
  font-family: GmarketSans;
  color: #FFF;
  cursor: pointer;

  @media all and (min-width:321px) and (max-width:567px) {
    font-size: 15px;
    margin-right: 0px;
    margin-left: 15px;
  }

  @media all and (max-width:320px) {
    font-size: 13px;
    margin-right: 0px;
    margin-left: 10px;
  }

  &:hover {
    color: #4874f6;
  }
`;

function Header({ changeLanguage }) {
  return (
    <Container>
      <UpperBackground>
        <UpperWrapper>
          <Logo src={header.logoImg} alt="apM coin logo" />
          <Languages>
            <Lauguage onClick={() => changeLanguage( 'ko' )}>KR</Lauguage>
            <Lauguage onClick={() => changeLanguage( 'en' )}>EN</Lauguage>
          </Languages>
        </UpperWrapper>
      </UpperBackground>
    </Container>
  );
}

export default Header;
