import React from 'react';
import styled from 'styled-components';

import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Download from '../Components/Download';

import { members } from '../meta/images';
import {
  appIntroduce, clearAdoption, qr, voucher, customizedService
} from '../meta/members';

const bg1 = require( '../assets/bg/img-bg-appscreens/img-bg-appscreens@3x.png' );
const bg2 = require( '../assets/bg/img-bg-phonemockup-main/img-bg-phonemockup-main@3x.png' );
const bg3 = require( '../assets/bg/img-bg-apmvoucher/img-bg-apmvoucher@3x.png' );

const Container = styled.main`
  min-width: 1250px;
  overflow: hidden;

  @media all and (min-width:568px) and (max-width:1023px) {
    max-width: 1050px;
    min-width: 700px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    max-width: 580px;
    min-width: 360px;
  }

  @media all and (max-width:320px) {
    max-width: 350px;
    min-width: 270px;
  }
`;

const Wrapper = styled.div`
  width: 1050px;
  margin: 0 auto;
  min-width: 1050px;

  @media all and (min-width:568px) and (max-width:1023px) {
    max-width: 1050px;
    min-width: 700px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    max-width: 450px;
    min-width: 350px;
  }

  @media all and (max-width:320px) {
    max-width: 350px;
    min-width: 270px;
  }
`;

const AppIntroBackground = styled.div`
  width: 100%;
  background-color: #141414;
  height: 960px;

  @media all and (min-width:568px) and (max-width:1023px) {
    height: 1030px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    height: 833px;
  }

  @media all and (max-width:320px) {
    height: 700px;
  }
`;

const AppIntroWrapper = styled.div`
  width: 1050px;
  min-width: 1050px;
  height: 840px;
  margin: 0 auto;
  position: relative;
  padding-top: 120px;

  @media all and (min-width:568px) and (max-width:1023px) {
    width: 600px;
    min-width: 600px;
    margin: 0 auto;
    position: static;
    padding-top: 80px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    max-width: 345px;
    min-width: 345px;
    margin: 0 auto;
    position: static;
    padding-top: 80px;
  }

  @media all and (max-width:320px) {
    max-width: 260px;
    min-width: 260px;
    margin: 0 auto;
    position: static;
    padding-top: 60px;
  }
`;

const AppIntroImage = styled.div`
  width: 960px;
  height: 840px;
  background-image: url(${( props ) => props.bgImage});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  animation: fadeIn 1s;
  
  @keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateZ(0);
    }
    50% {
        opacity: 0;
        transform: translate3d(5%, 0, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
  }

  @media all and (min-width:1024px) {
    position: absolute;
    top: 121px;
    right: -255px;
  }

  @media all and (min-width:568px) and (max-width:1023px) {
    width: 600px;
    height: 430px;
    margin-top: ${({ lang }) => ( lang === 'ko' ? '62px' : '20px' )}
  }

  @media all and (min-width:321px) and (max-width:567px) {
    width: 345px;
    height: 400px;
    margin-top: ${({ lang }) => ( lang === 'ko' ? '25px' : '4px' )}
  }

  @media all and (max-width:320px) {
    min-width: 260px;
    width: 260px;
    height: 280px;
    margin-top: ${({ lang }) => ( lang === 'ko' ? '32px' : '14px' )}
  }
`;

const AppIntroduce = styled.div`
  .text-line {
    font-family: GmarketSans;
    font-size: 53px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -1.75px;
    color: #4874f6;
  }
  animation: fadeInText 1s;
  
  @keyframes fadeInText {
    0% {
        opacity: 0;
        transform: translateZ(0);
    }
    50% {
        opacity: 0;
        transform: translate3d(0, 10%, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
  }

  @media all and (min-width:568px) and (max-width:1023px) {
    .text-line {
      font-size: 45px;
    }
  }

  @media all and (min-width:321px) and (max-width:567px) {
    .text-line {
      font-size: 28px;
    }
  }

  @media all and (max-width:320px) {
    .text-line {
      font-size: 24px;
    }
  }
`;

const AppIconContainer = styled.div`
  margin-top: 250px;
  animation: fadeInText 1s;
  
  @keyframes fadeInText {
    0% {
        opacity: 0;
        transform: translateZ(0);
    }
    50% {
        opacity: 0;
        transform: translate3d(0, 10%, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
  }

  @media all and (min-width:568px) and (max-width:1023px) {
    margin-top: 40px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    margin-top: 40px;
  }

  @media all and (max-width:320px) {
    margin-top: 30px;
  }
`;

const AppIcon = styled.img`
  height: 72px;

  @media all and (min-width:568px) and (max-width:1023px) {
    height: 67px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    height: 60px;
  }

  @media all and (max-width:320px) {
    height: 50px;
  }
`;

const AppIconContent = styled.div`
  font-family: GmarketSans;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.75px;
  color: #fff;
  .title {
    margin-top: 24px;
    font-size: 24px;
    font-weight: bold;
  }
  .description {
    width: 450px;
    margin-top: 12px;
    font-size: 14px;
    font-weight: normal;
    word-break: keep-all;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    .title {
      margin-top: 16px;
      font-size: 18px;
      font-weight: bold;
    }
    .description {
      width: ${( props ) => ( props.lang === 'ko' ? '280px' : '100%' )};
      margin-top: 12px;
      font-size: 14px;
      font-weight: normal;
    }
  }

  @media all and (max-width:320px) {
    .title {
      margin-top: 16px;
      font-size: 16px;
      font-weight: bold;
    }
    .description {
      width: ${( props ) => ( props.lang === 'ko' ? '250px' : '100%' )};
      margin-top: 12px;
      font-size: 12px;
      font-weight: normal;
    }
  }
`;

const TitleBackground = styled.div`
  width: 100%;
  background-color: #141414;
`;

const TitleWrapper = styled.div`
  width: 1050px;
  min-width: 1050px;
  height: 900px;
  margin: 0 auto;
  position: relative;

  @media all and (min-width:568px) and (max-width:1023px) {
    min-width: 600px;
    width: 600px;
    height: ${({ lang }) => ( lang === 'ko' ? '2060px' : '2060px' )};
  }

  @media all and (min-width:321px) and (max-width:567px) {
    min-width: 345px;
    width: 345px;
    height: ${({ lang }) => ( lang === 'ko' ? '1310px' : '1350px' )};
  }

  @media all and (max-width:320px) {
    min-width: 260px;
    width: 260px;
    height: ${({ lang }) => ( lang === 'ko' ? '1170px' : '1230px' )};
  }
`;

const TitleImage = styled.div`
  width: 1500px;
  height: 900px;
  background-image: url(${( props ) => props.bgImage});
  background-size: 1400px 900px;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  right: -520px;
  opacity: 0.6;

  @media all and (min-width:568px) and (max-width:1023px) {
    right: -400px;
    opacity: 0.6;
    z-index: 0;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    height: 480px;
    background-size: 700px 480px;
    z-index: 0;
  }

  @media all and (max-width:320px) {
    width: 400px;
    height: 380px;
    background-size: 700px 380px;
    right: -80px;
    opacity: 0.6;
    z-index: 0;
  }
`;

const TitleWeb = styled( Wrapper )`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -1.75px;
  color: #4874f6;
  font-family: GmarketSans;
  padding-top: 248px;
  margin-bottom: 68px;

  @media all and (min-width:1px) and (max-width:1023px) {
    display: none;
  }
`;

const TitleMobile = styled( Wrapper )`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -1.75px;
  color: #4874f6;
  font-family: GmarketSans;
  padding-top: 248px;
  margin-bottom: 68px;
  z-index: 1;
  position: absolute;
  top: 400px;

  @media all and (min-width:1023px) {
    display: none;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    font-size: 35px;
    padding-top: 0px;
    top: 330px;
  }  
  
  @media all and (max-width:320px) {
    font-size: 30px;
    padding-top: 0px;
    top: 250px;
  }
`;

const AppIntroFeatureContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media all and (min-width:568px) and (max-width:1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }

  @media all and (max-width:320px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }
`;

const FeatureContainer = styled.div`
  width: 510px;
  min-height: ${( props ) => props.height};
  background-color: #000;
  z-index: 1;
  padding: 30px;
  
  @media all and (min-width:568px) and (max-width:1023px) {
    margin-bottom: 30px;
    margin-top: ${( props ) => props.top};
  }

  @media all and (min-width:321px) and (max-width:567px) {
    width: 350px;
    margin-bottom: 16px;
    padding: 20px 15px;
    margin-top: ${( props ) => props.moTop};
    min-height: ${( props ) => props.moHeight};
  }

  @media all and (max-width:320px) {
    max-width: 260px;
    margin-bottom: 20px;
    padding: 20px 10px;
    margin-top: ${( props ) => props.minTop};
    min-height: ${( props ) => props.minHeight};
  }
`;

const FeatureContainerTopHidden = styled.div`
  width: 510px;
  height: 224px;
  background-color: #000;
  visibility: hidden;

  @media all and (min-width:1px) and (max-width:1023px) {
    display: none;
  }
`;

const FeatureTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media all and (max-width:320px) {
    margin-bottom: 10px;
  }
`;

const FeatureTitle = styled.div`
  font-family: GmarketSans;
  width: 100%;
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  color: #4874f6;
  word-break: keep-all;
  letter-spacing: -0.88px;
  margin-top: 10px;

  @media all and (min-width:568px) and (max-width:1023px) {
    font-size: 20px;
    margin-top: 7px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    font-size: 15px;
    margin-top: 5px;
  }

  @media all and (max-width:320px) {
    font-size: 13px;
    margin-top: 5px;
  }
`;

const FeatureSubTitle = styled.div`
  width: 90%;
  font-size: 13px;
  color: #fff;
  line-height: 1.2;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.88px;
  font-weight: bold;

  @media all and (min-width:568px) and (max-width:1023px) {
    margin-top: 10px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    font-size: 12px;
    margin-top: 7px;
  }

  @media all and (max-width:320px) {
    font-size: 11px;
    margin-top: 5px;
  }
`;

const FeatureImage = styled.img`
  width: 72px;
  height: 72px;
  object-fit: contain;

  @media all and (max-width: 568px)  and (max-width:1023px) {
    height: 48px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    font-size: 12px;
    margin-top: 7px;
  }

  @media all and (max-width:320px) {
    font-size: 11px;
    margin-top: 5px;
  }
`;

const FeatureDescription = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.88px;
  color: #fff;
  word-break: keep-all;
  margin-top: 10px;

  li {
    display: table;
    list-style: none;
    margin-bottom: 12px;
  }
  li::before {
    content: "\u00B7";
    font-weight: 900;
    zoom: 1;
    display: table-cell;
    text-align: right;
    padding-right: 1em;
  }

  @media all and (min-width:320px) and (max-width:567px) {
    font-size: 12px;

    li {
      margin-bottom: 6px;
    }
  }

  @media all and (max-width:320px) {
    font-size: 11px;

    li {
      margin-bottom: 6px;
    }
  }
`;

const VoucherBackground = styled.div`
  width: 100%;
  background-color: #141414;
  height: 700px;

  @media all and (min-width:568px) and (max-width:1023px) {
    height: 480px;
    padding-top: 40px;
  }

  @media all and (max-width:567px) {
    height: 100%;
  }
`;

const VoucherWrapper = styled.div`
  margin: 0 auto;
  
  @media all and (min-width:1024px) {
    width: 1050px;
    min-width: 1050px;
    height: 700px;
  }
  
  @media all and (min-width:568px) and (max-width:1023px) {
    display: flex;
    justify-content: center;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    display: flex;
    justify-content: center;
  }

  @media all and (max-width:320px) {
    display: flex;
    justify-content: center;
  }
`;

const VoucherImage = styled.img`
  @media all and (min-width:1023px) {
    height: 750px;
    margin-left: 50px;
    padding-top: 50px;
  }

  @media all and (min-width:568px) and (max-width:1023px) {
    height: 450px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    width: 370px;
  }

  @media all and (max-width:320px) {
    width: 290px;
  }
`;

const DescriptionWrapper = styled.div`
  min-width: 1050px;
  video {
    max-width: 100%;
    width: 100%;
    height: 600px;
    border: none;
    filter: grayscale(100%);
    object-fit: cover;
    margin-bottom: -5px;
  }

  @media all and (min-width:568px) and (max-width:1023px) {
    min-width: 600px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    min-width: 450px;
    video {
      height: 250px;
    }
  }

  @media all and (max-width:320px) {
    min-width: 300px;
    video {
      height: 200px;
    }
  }
`;

function Members({ languageHandler }) {
  const { lang, changeLanguage } = languageHandler;

  return (
    <Container>
      <Header changeLanguage={changeLanguage} />

      <AppIntroBackground>
        <AppIntroWrapper>
          <AppIntroduce>
            <div className="text-line">Blockchain-applied</div>
            <div className="text-line">Mobile App Service for</div>
            <div className="text-line">the Korea’s Leading</div>
            <div className="text-line">Wholesale Fashion Malls</div>
          </AppIntroduce>

          <AppIconContainer>
            <AppIcon src={members.appIcon} alt="app-icon" />
            <AppIconContent lang={lang}>
              <div className="title">apM Members Mobile App</div>
              <div className="description">{appIntroduce[lang]}</div>
            </AppIconContent>
          </AppIconContainer>

          <AppIntroImage bgImage={bg2} lang={lang} />
        </AppIntroWrapper>
      </AppIntroBackground>

      <Download />

      <TitleBackground>
        <TitleWrapper lang={lang}>
          <TitleImage bgImage={bg1} />
          <TitleWeb>
            About
            <br />
            apM Members
          </TitleWeb>
          <TitleMobile>
            apM Members
            <br />
            Mobile App
          </TitleMobile>

          {/* line 1 */}
          <AppIntroFeatureContainer>
            <FeatureContainer height="224px" top="900px" moTop="480px" moHeight="150px" minTop="380px" minHeight="100px">
              <FeatureTitleContainer>
                <FeatureTitle>
                  {clearAdoption[lang].title}
                </FeatureTitle>
                <FeatureImage src={members.storeImg} alt="store" />
              </FeatureTitleContainer>
              <FeatureDescription lang={lang}>
                {clearAdoption[lang].text.map( ( feature ) => ( <li key={`${feature.id}-${lang}`}>{feature.value}</li> ) )}
              </FeatureDescription>
            </FeatureContainer>
            <FeatureContainerTopHidden />
          </AppIntroFeatureContainer>

          {/* line2 */}
          <AppIntroFeatureContainer>
            <FeatureContainer height="260px" moHeight="150px" minHeight="100px">
              <FeatureTitleContainer>
                <FeatureTitle>
                  {qr[lang].title}
                </FeatureTitle>
                <FeatureImage src={members.qrcodeImg} alt="qrcode" />
              </FeatureTitleContainer>
              <FeatureDescription lang={lang}>
                {qr[lang].text.map( ( feature ) => ( <li key={`${feature.id}-${lang}`}>{feature.value}</li> ) )}
              </FeatureDescription>
            </FeatureContainer>
            <FeatureContainer height="260px" moHeight="150px" minHeight="100px">
              <FeatureTitleContainer>
                <FeatureTitle>
                  {voucher[lang].title}
                </FeatureTitle>
                <FeatureImage src={members.apmvoucherImg} alt="apmvoucher" />
              </FeatureTitleContainer>
              <FeatureSubTitle>{voucher[lang].description}</FeatureSubTitle>
              <FeatureDescription lang={lang}>
                {voucher[lang].text.map( ( feature ) => ( <li key={`${feature.id}-${lang}`}>{feature.value}</li> ) )}
              </FeatureDescription>
            </FeatureContainer>
          </AppIntroFeatureContainer>

          {/* line 3 */}
          <AppIntroFeatureContainer>
            <FeatureContainerTopHidden />
            <FeatureContainer height="302px" moHeight="150px" minHeight="100px">
              <FeatureTitleContainer>
                <FeatureTitle>
                  {customizedService[lang].title}
                </FeatureTitle>
                <FeatureImage src={members.usersImg} alt="users" />
              </FeatureTitleContainer>
              <FeatureSubTitle>{customizedService[lang].description}</FeatureSubTitle>
              <FeatureDescription lang={lang}>
                {customizedService[lang].text.map( ( feature ) => ( <li key={`${feature.id}-${lang}`}>{feature.value}</li> ) )}
              </FeatureDescription>
            </FeatureContainer>
          </AppIntroFeatureContainer>
        </TitleWrapper>
      </TitleBackground>

      <VoucherBackground>
        <VoucherWrapper>
          <VoucherImage src={bg3} alt="voucher" />
        </VoucherWrapper>
      </VoucherBackground>

      <DescriptionWrapper>
        <video src="https://cdn.apm-coin.com/201201_web_main.mp4" autoPlay loop muted="muted" playsInline controlsList="nodownload">
          <track kind="captions" />
        </video>
      </DescriptionWrapper>

      <Footer />
    </Container>
  );
}

export default Members;
