import React from 'react';
import ReactGA from 'react-ga4';
import GlobalStyles from './GlobalStyles';

import Members from './Page/Members';

function App() {
  const userLang = navigator.language || navigator.userLanguage;

  const [ lang, setLang ] = React.useState( userLang === 'ko-KR' ? 'ko' : 'en' ); // ko | en

  const changeLanguage = ( language ) => {
    setLang( language );
  };

  const languageHandler = {
    lang,
    changeLanguage,
  };

  React.useEffect( () => {
    ReactGA.initialize( 'G-KTRZ5EVMY8' );
    ReactGA.event( 'page_opened' );
  }, []);

  return (
    <div>
      <GlobalStyles />
      <Members languageHandler={languageHandler} />
    </div>
  );
}

export default App;
