import React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import { download } from '../meta/images';

const Container = styled.div``;

const Wrapper = styled.div`
  height: 140px;
  display: flex;
  align-items: center;
  background-color: #4874f6;

  @media all and (min-width:321px) and (max-width:567px) {
    height: 80px;
  }

  @media all and (max-width:320px) {
    height: 80px;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1050px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media all and (min-width:568px) and (max-width:1023px) {
    max-width: 500px;
    justify-content: center;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    max-width: 345px;
    justify-content: center;
  }

  @media all and (min-width:281px)  and (max-width:320px) {
    max-width: 270px;
    justify-content: center;
  }

  @media all and (max-width:280px) {
    max-width: 230px;
    justify-content: center;
  }
`;

const Title = styled.div`
  width: 384px;
  font-family: GmarketSans;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.75px;
  color: #fff;

  @media all and (min-width:568px) and (max-width:1023px) {
    font-size: 20px;
  }

  @media all and (max-width:1023px) {
    display: none;
  }
`;

const ButtonList = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.div`
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;

  @media all and (min-width:568px) and (max-width:1023px) {
    margin: 8px 8px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    margin: 7px 7px;
  }

  @media all and (max-width:320px) {
    margin: 4px 4px;
  }
`;

const AppIntroDownloadButtonImg = styled.img`
  width: 160px;
  height: 48px;

  @media all and (min-width:568px) and (max-width:1023px) {
    width: 160px;
    height: 48px;
  }

  @media all and (min-width:321px) and (max-width:567px) {
    width: 100px;
    height: 30px;
  }

  @media all and (max-width:320px) {
    width: 82px;
    height: 27px;
  }
`;

function Download() {
  const onClickAppStoreButton = () => {
    window.open( 'https://apps.apple.com/app/id1579002466' );
  };
  const onClickGooglePlayButton = () => {
    window.open( 'https://play.google.com/store/apps/details?id=com.apmsns.members' );
  };
  const onClickApkButton = () => {
    ReactGA.event( 'apk_download' );
    window.open( 'https://cdn.apm-members.com/downloads/apM_Members_release.apk' );
  };

  return (
    <Container>
      <Wrapper>
        <InnerWrapper>
          <Title>Download apM Members</Title>
          <ButtonList>
            <Button onClick={onClickAppStoreButton}>
              <AppIntroDownloadButtonImg id="ga-download-click-ios" src={download.appStore} alt="app-store" />
            </Button>
            <Button onClick={onClickGooglePlayButton}>
              <AppIntroDownloadButtonImg id="ga-download-click-android" src={download.googlePlay} alt="google-play" />
            </Button>
            <Button onClick={onClickApkButton}>
              <AppIntroDownloadButtonImg id="ga-download-click-apk" src={download.downloadIcon} alt="apk-download" />
            </Button>
          </ButtonList>
        </InnerWrapper>
      </Wrapper>
    </Container>
  );
}

export default Download;
