// Header
import logoImg from '../assets/logo-medium-black/logo-medium-black@3x.png';

// Download
import googlePlay from '../assets/btn-googleplay/btn-googleplay@3x.png';
import storeImg from '../assets/rsc-img-store/rsc-img-store@3x.png';
import downloadIcon from '../assets/btn-apk/btn-apk@3x.png';

// MEMBERS
import appIcon from '../assets/rsc-img-appicon/rsc-img-appicon@3x.png';
import appStore from '../assets/btn-appstore/btn-appstore@3x.png';
import qrcodeImg from '../assets/rsc-img-qrcode/rsc-img-qrcode@3x.png';
import apmvoucherImg from '../assets/rsc-img-apmvoucher/rsc-img-apmvoucher@3x.png';
import usersImg from '../assets/rsc-img-users/rsc-img-users@3x.png';

export const header = { logoImg };

export const download = { appStore, googlePlay, downloadIcon };

export const members = {
  appIcon, appStore, googlePlay, storeImg, qrcodeImg, apmvoucherImg, usersImg,
};
